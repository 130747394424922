import { Scroller, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { Box, Container, SxProps, Theme } from '@mui/material'
import { UspsQueryFragment } from './UspsQueryFragment.gql'

export type ProductUspsProps = UspsQueryFragment & {
  sx?: SxProps<Theme>
}

export function UspsHorizontalList(props: ProductUspsProps) {
  const { usps, sx = [] } = props

  if (!usps?.uspsMultiple) return null
  const uspsLength = usps.uspsMultiple.length - 1

  return (
    <Container maxWidth={false} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <ScrollerProvider scrollSnapAlign='none'>
        <Scroller
          hideScrollbar
          sx={(theme) => ({
            gap: theme.spacings.sm,
            justifyContent: 'left',
            gridTemplateColumns: `repeat(${uspsLength}, auto) 1fr`,
            height: '34px',
          })}
        >
          {usps?.uspsMultiple.map((usp) => {
            if (!usp.description && !usp.asset) return null
            return (
              <Box
                key={usp.title}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  color: theme.palette.primary.dark,
                  gap: 1,
                  '&:last-child': { justifySelf: 'flex-end', mr: 1 },
                })}
              >
                {usp.asset && (
                  <Box sx={{ flexShrink: 0 }}>
                    <Asset
                      asset={{ ...usp.asset, width: 77, height: 12 }}
                      sizes={{ 0: '77px' }}
                      unoptimized
                      layout='fill'
                      sx={{
                        color: 'text.primary',
                        objectFit: 'contain',
                        width: `${
                          usp.asset.width &&
                          usp.asset.height &&
                          usp.asset.width / usp.asset.height > 5
                            ? 'auto'
                            : '12px'
                        } !important`,
                        height: '12px',
                      }}
                    />
                  </Box>
                )}
                {usp.description && (
                  <RichText
                    raw={usp.description?.raw}
                    sxRenderer={{
                      paragraph: {
                        typography: 'caption',
                        m: 0,
                      },
                      link: {
                        typography: 'caption',
                        color: 'inherit',
                        textDecoration: 'underline',
                      },
                      image: {
                        '& img': {
                          height: '18px',
                          verticalAlign: 'text-top',
                          mr: '8px',
                          width: 'auto',
                        },
                      },
                    }}
                  />
                )}
              </Box>
            )
          })}
        </Scroller>
      </ScrollerProvider>
    </Container>
  )
}
