import { PageOptions } from '@graphcommerce/framer-next-pages'
import { hygraphPageContent, HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import {
  GetStaticProps,
  LayoutHeader,
  MetaRobots,
  PageMeta,
  storefrontConfig,
} from '@graphcommerce/next-ui'
import {
  LayoutDocument,
  LayoutNavigation,
  LayoutNavigationProps,
  RowProduct,
  RowRenderer,
} from '../components'
import { RowBannerSlider } from '../components/GraphCMS/RowBannerSlider/RowBannerSlider'
import { RowQuote } from '../components/GraphCMS/RowQuote/RowQuote'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & ProductListQuery
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, products } = props
  const product = products?.items?.[0]
  const page = pages?.[0]

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      <LayoutHeader floatingMd floatingSm />
      {page && (
        <RowRenderer
          content={page?.content}
          renderer={{
            RowProduct: (rowProps) => (
              <RowProduct {...rowProps} {...product} items={products?.items} />
            ),
            RowQuote: (rowProps) => (
              <RowQuote
                {...rowProps}
                sx={(theme) => ({
                  '& .MuiBox-root img': {
                    width: { xs: 70, sm: 80, md: 90, lg: 100 },
                    marginBottom: theme.spacings.xs,
                  },
                })}
              />
            ),
            RowBannerSlider: (rowProps) => (
              <RowBannerSlider
                {...rowProps}
                maxWidth='lg'
                sx={(theme) => ({ marginBottom: { xs: theme.spacings.lg, md: theme.spacings.md } })}
              />
            ),
          }}
        />
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({
    query: LayoutDocument,
    fetchPolicy: 'cache-first',
    variables: {
      scopes: storefrontConfig(locale)?.hygraphScopes,
    },
  })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  const productList = staticClient.query({
    query: ProductListDocument,
    variables: {
      pageSize: 8,
      filters: { category_uid: { eq: storefrontConfig(locale)?.homepageCategoryUid } },
    },
  })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      ...(await productList).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
