import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { IconSvg } from '@graphcommerce/next-ui'
import iconSmartphone from '@graphcommerce/next-ui/icons/smartphone.svg'
import { i18n } from '@lingui/core'
import { Button, Fab, Typography, styled } from '@mui/material'

const PhoneNumberWrapper = styled('div')(({ theme }) => ({
  '& .MuiFab-root': {
    columnGap: `calc(${theme.spacings.xs} * 0.5)`,
  },
}))

export function PhoneNumber() {
  const { data: config } = useQuery(StoreConfigDocument)
  const storePhoneNumber = config?.storeConfig?.store_telephone

  if (!storePhoneNumber) return null

  const formattedPhoneNumber = storePhoneNumber.replace(/\D/g, '')
  const phoneNumberUrl = `tel:+31${formattedPhoneNumber.substring(1)}`

  return (
    <PhoneNumberWrapper>
      <Fab
        href={phoneNumberUrl}
        aria-label={i18n._(/* i18n */ 'Phone Number')}
        size='large'
        color='inherit'
        sx={{ display: { xs: 'none', md: 'flex', lg: 'none' } }}
      >
        <IconSvg src={iconSmartphone} size='large' />
      </Fab>

      <Button
        href={phoneNumberUrl}
        variant='text'
        size='medium'
        sx={{
          width: 'auto',
          height: 'auto',
          display: { xs: 'flex', md: 'none', lg: 'flex' },
        }}
      >
        <IconSvg src={iconSmartphone} size='large' />
        <Typography variant='subtitle2' component='p' sx={{ ml: '8px' }}>
          {storePhoneNumber}
        </Typography>
      </Button>
    </PhoneNumberWrapper>
  )
}
