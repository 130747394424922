import { IconSvg } from '@graphcommerce/next-ui'
import iconSupport from '@graphcommerce/next-ui/icons/support.svg'
import { i18n } from '@lingui/core'
import { Button, Fab, Typography, styled } from '@mui/material'

type Props = {
  pageLink: string
  description: string
}

const CustomerServiceWrapper = styled('div')(({ theme }) => ({
  '& .MuiFab-root': {
    columnGap: `calc(${theme.spacings.xs} * 0.5)`,
  },
  '& .MuiButtonBase-root': {
    '&:nth-of-type(1)': {
      display: {
        xs: 'none',
        md: 'flex',
        lg: 'none',
      },
    },
    '&:nth-of-type(2)': {
      display: {
        xs: 'flex',
        md: 'none',
        lg: 'flex',
      },
    },
  },
}))

export function CustomerService(props: Props) {
  const { pageLink, description } = props

  if (!pageLink || !description) return null

  return (
    <CustomerServiceWrapper>
      <Fab
        href={pageLink}
        aria-label={i18n._(/* i18n */ 'Phone Number')}
        size='large'
        color='inherit'
        sx={{ display: { xs: 'none', md: 'flex', lg: 'none' } }}
      >
        <IconSvg src={iconSupport} size='large' />
      </Fab>

      <Button
        href={pageLink}
        variant='text'
        size='medium'
        sx={{ width: 'auto', height: 'auto', display: { xs: 'flex', md: 'none', lg: 'flex' } }}
      >
        <IconSvg src={iconSupport} size='large' />
        <Typography variant='subtitle2' component='p' sx={{ ml: '8px' }}>
          {description}
        </Typography>
      </Button>
    </CustomerServiceWrapper>
  )
}
