import {
  DesktopNavBar,
  DesktopNavItem,
  IconSvg,
  UseNavigationSelection,
  iconChevronDown,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Badge } from '@mui/material'
import { NavigationItemChildFragment } from './NavigationItemChild.gql'

type NavigationProps = {
  items: NavigationItemChildFragment[]
  selection: UseNavigationSelection
}

const renderBadge = (item: NavigationItemChildFragment) => {
  const { labelText, labelColor } = item
  const showLabel = labelText && labelColor
  const showBadge = !labelText && labelColor

  if (showLabel) return <Badge color='primary' badgeContent={labelText} />
  if (showBadge) return <Badge color='primary' variant='dot' />
  return null
}

export function Navigation({ items, selection }: NavigationProps) {
  return (
    <DesktopNavBar
      sx={(theme) => ({
        flex: 1,
        minHeight: theme.appShell.headerHeightSm,
        borderBottom: `1px solid ${theme.palette.divider}`,
        '& .Scroller-root': { px: theme.spacings.sm },
      })}
    >
      <DesktopNavItem onClick={() => selection.set(['products'])}>
        <Trans id='Products' />
        <IconSvg src={iconChevronDown} />
      </DesktopNavItem>
      {items.map((item) => {
        const { id, labelColor, labelText, name, url: originalUrl } = item
        const hasLabel = labelText
        const hasLabelColor = labelColor
        const showLabel = hasLabel && hasLabelColor
        const showBadge = !hasLabel && hasLabelColor
        const isHighlighted = showLabel || showBadge
        const badgeColor = labelColor?.replace('hex_', '#') || null

        if (!name || !originalUrl) return null

        let processedUrl = originalUrl
        if (!processedUrl.startsWith('/')) {
          processedUrl = `/${processedUrl}`
        }

        return (
          <DesktopNavItem
            key={id}
            href={processedUrl}
            sx={{
              ...(isHighlighted && {
                '& .MuiBox-root': {
                  fontWeight: '650',
                  fontVariationSettings: '"wght" 650',
                },
                '& .MuiBadge-badge': {
                  mr: 1,
                  backgroundColor: badgeColor,

                  ...(showLabel && {
                    position: 'relative',
                    transform: 'none',
                    borderRadius: '4px',
                    mr: 0.5,
                  }),
                },
              }),
            }}
          >
            {renderBadge(item)}
            {name}
          </DesktopNavItem>
        )
      })}
    </DesktopNavBar>
  )
}
