import { IconSvg, useScrollY, iconMenu, useFabSize } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Fab, styled, Box, SxProps, Theme, FabProps, Typography } from '@mui/material'
import { m, useTransform } from 'framer-motion'
import React from 'react'

const MotionDiv = styled(m.div)({})

export type NavigationFabProps = {
  menuIcon?: React.ReactNode
  closeIcon?: React.ReactNode
  sx?: SxProps<Theme>
} & Pick<FabProps, 'color' | 'size' | 'variant' | 'onClick'>

export function NavigationMenuFab(props: NavigationFabProps) {
  const { menuIcon, closeIcon, sx = [], size = 'responsive', ...fabProps } = props

  const scrollY = useScrollY()
  const opacity = useTransform(scrollY, [30, 60], [0, 1])

  const fabSize = useFabSize(size)

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <MotionDiv
        sx={(theme) => ({
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
        style={{ opacity }}
      >
        <Fab
          color='inherit'
          aria-label='Open Menu'
          size={size}
          variant='extended'
          sx={(theme) => ({
            width: 'unset',
            display: 'flex',
            boxShadow: 'none',
            '&:focus': {
              background: theme.palette.primary.main,
            },
            background: theme.palette.primary.main,
            alignItems: 'center',
            gap: theme.spacings.xxs,
            px: theme.spacings.xxs,
            pointerEvents: 'all',
            color: theme.palette.background.paper,
            borderRadius: fabSize,
          })}
          {...fabProps}
        >
          <IconSvg src={iconMenu} size='large' />
          <Typography variant='subtitle2' component='p' sx={{ lineHeight: '1.5em' }}>
            <Trans id='Menu' />
          </Typography>
        </Fab>
      </MotionDiv>
    </Box>
  )
}
